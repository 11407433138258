<template>
<Loader v-if="loading" :logo="loaderLogo" />
</template>

<script>
import { CONFIRM_REGISTER } from '@/core/services/store/auth.module'

import Loader from '../../content/Loader'

export default {
  name: 'ConfirmRegister',
  components: { Loader },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    loaderLogo() {
      return `${process.env.BASE_URL}media/logos/alioth_dark.svg`
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      if (!this.$route.query.token) this.onRedirect()
      this.$store.dispatch(CONFIRM_REGISTER, this.$route.query.token).then(() => {
        this.onRedirect(true)
      })
        .catch(() => {
          this.onRedirect(false)
        })
    },
    onRedirect(val) {
      this.$router.replace({
        name: 'login',
        query: { confirmRegister: val }, 
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
